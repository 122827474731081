import { useContext } from "react";
import { Language } from "../../infrastructure/Languages";
import { SectionTitle } from "../../core/SectionTitle";
import "./ProductCertificationDisplay.css";
import { useGlobalOfferId } from "../../infrastructure/GlobalOfferId";
import { DownloadLink } from "../../core/DownloadLink";

export const ProductCertificationsDisplay = ({ certificates, googleAnalyticsContext }) => {
    const language = useContext(Language);
    const globalOfferId = useGlobalOfferId();

    const getCertificateTypes = () => {
        if (certificates) {
            return certificates.map(item => item.certificationTypeName)
                .filter((value, index, self) => self.indexOf(value) === index)
        }
        return [];
    }

    return (
        <div>
            <SectionTitle cms="eyemark-placement" gaContext={googleAnalyticsContext}>
                {language.translateLabel("80231fa2-1275-438d-9eea-1b5918c1d461", "Certifications")}
            </SectionTitle>
            {
                <div className="sectionLabel">
                    <div className="certifications">
                        {getCertificateTypes().map((txt, idx) => {
                            return (
                                <div className="description" key={"cert" + idx}>
                                    {txt}
                                </div>
                            );
                        })}
                        <div>
                            <DownloadLink>
                                <a
                                    href={`offer/${globalOfferId.globalOfferId}/certificates`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {language.translateLabel(
                                        "8f309e19-df3d-49d2-a298-f0995eeea064",
                                        "Download"
                                    )}
                                </a>
                            </DownloadLink>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
